import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hideDialog } from '../actions';
import ModalDialog from './Modal';
import FilePreviewModal from './FilePreviewModal';
// eslint-disable-next-line
class FilePreviewDialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    className: PropTypes.string,
    closeDialog: PropTypes.func.isRequired,
    onReady: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onReadyParams: PropTypes.shape({}),
    fileAddedCallback: PropTypes.func.isRequired,
  };

  render() {
    const { closeDialog, isOpen, title, text, onReady, onReadyParams, files } = this.props;
    return (
      <ModalDialog
        title={title}
        onReady={onReady}
        onReadyParams={onReadyParams}
        isOpen={isOpen}
        close={closeDialog}
        className={'enhanced-dialog large padded'}
        children={<FilePreviewModal files={files} text={text} />}
        submitButtonText="Upload All"
      ></ModalDialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ closeDialog: hideDialog }, dispatch);
};

export default connect(null, mapDispatchToProps)(FilePreviewDialog);
