import { API_REQUEST, fetchSiteSettings } from '../../core/actions';
import * as API from '../../core/api/detergents';
import { viewPermissionRefs } from '../../core/components/UserGroupPermissions';

export const ADD_DETERGENT_TEMP = 'ADD_DETERGENT_TEMP';
export const ADD_DETERGENTS_TEMP = 'ADD_DETERGENTS_TEMP`';
export const SAVE_DETERGENT_TEMP = 'SAVE_DETERGENT_TEMP';
export const DELETE_DETERGENT_TEMP = 'DELETE_DETERGENT_TEMP';
export const GET_DETERGENT_TEMP = 'GET_DETERGENT_TEMP';
export const GET_DETERGENTS_TEMP = 'GET_DETERGENTS_TEMP';
export const GET_CHART_DATA = 'GET_CHART_DATA';
export const ADD_DETERGENT_READING_TEMP = 'ADD_DETERGENT_READING_TEMP';
export const SET_COST_PER_CAR_CHART_FROM_DATE = 'SET_COST_PER_CAR_CHART_FROM_DATE';
export const SET_COST_PER_CAR_CHART_UNTIL_DATE = 'SET_COST_PER_CAR_CHART_UNTIL_DATE';
export const GET_DETERGENT_READINGS_TEMP = 'GET_DETERGENT_READINGS_TEMP';
export const SHOW_DETERGENT_SUMMARY_CHART = 'SHOW_DETERGENT_SUMMARY_CHART';
export const SET_DETERGENT_READINGS_FROM_DATE_TEMP = 'SET_DETERGENT_READINGS_FROM_DATE_TEMP';
export const SET_DETERGENT_READINGS_UNTIL_DATE_TEMP = 'SET_DETERGENT_READINGS_UNTIL_DATE_TEMP';
export const REMOVE_DETERGENT_READINGS = 'REMOVE_DETERGENT_READINGS';
export const SET_DETERGENT_SELECTION = 'SET_DETERGENT_SELECTION';
export const GET_DETERGENT_PODS = 'GET_DETERGENT_PODS';
export const GET_DETERGENT_POD = 'GET_DETERGENT_POD';
export const ADD_DETERGENT_POD = 'ADD_DETERGENT_POD';
export const SAVE_DETERGENT_POD = 'SAVE_DETERGENT_POD';
export const ARCHIVE_DETERGENT_POD = 'ARCHIVE_DETERGENT_POD';
export const POST_DETERGENT_POD_TEMPLATE = 'POST_DETERGENT_POD_TEMPLATE';
export const ARCHIVE_DETERGENT = 'ARCHIVE_DETERGENT';
export const CLEAR_DETERGENT_SELECTIONS = 'CLEAR_DETERGENT_SELECTIONS';
export const UPSERT_DETERGENT_TO_DETERGENT_POD = 'UPSERT_DETERGENT_TO_DETERGENT_POD';
export const UPSERT_DETERGENT_CONTAINER = 'UPSERT_DETERGENT_CONTAINER';

export const getDetergentPods = (query) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentPods,
  action: GET_DETERGENT_PODS,
  data: query,
  meta: { permissionKey: viewPermissionRefs.detergent, useManagerSiteId: true },
});

export const getDetergentPod = (id) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentPod,
  action: GET_DETERGENT_POD,
  data: { id },
  meta: { permissionKey: viewPermissionRefs.detergent, useManagerSiteId: true },
});

export const addDetergentPod = (detergentPod) => ({
  type: API_REQUEST,
  callAPI: API.postDetergentPod,
  data: detergentPod,
  action: ADD_DETERGENT_POD,
  callback: () => getDetergentPods(),
  closeModal: true,
});

export const saveDetergentPod = (detergentPod, id) => ({
  type: API_REQUEST,
  callAPI: API.patchDetergentPod,
  action: SAVE_DETERGENT_POD,
  data: { ...detergentPod, id },
  callback: () => getDetergentPods(),
  closeModal: true,
});

export const handleDetergentsToDetergentPod = (values, remove = false) => {
  const { detergentPodId } = values;
  return {
    type: API_REQUEST,
    callAPI: API.postDetergentsToDetergentPod,
    action: UPSERT_DETERGENT_CONTAINER,
    data: { id: detergentPodId, ...values, removeDetergents: remove },
    callback: () => getDetergentPods(),
    closeModal: true,
  };
};

export const upsertDetergentContainer = (values) => {
  const { detergentContainerId } = values;
  return {
    type: API_REQUEST,
    callAPI: API.patchDetergentContainer,
    action: UPSERT_DETERGENT_TO_DETERGENT_POD,
    data: { id: detergentContainerId, ...values },
    callback: () => getDetergentPods(),
    closeModal: true,
  };
};

export const archiveDetergentPod = (id) => ({
  type: API_REQUEST,
  callAPI: API.archiveDetergentPod,
  action: ARCHIVE_DETERGENT_POD,
  data: { id },
  callback: () => getDetergentPods(),
  closeModal: true,
});

export const postDetergentPodTemplate = () => ({
  type: API_REQUEST,
  callAPI: API.postDetergentPodTemplate,
  action: 'POST_DETERGENT_POD_TEMPLATE',
  callback: [() => getDetergentPods(), () => fetchSiteSettings()],
});

export const getDetergents = (query) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentsTemp,
  action: GET_DETERGENTS_TEMP,
  data: query,
  meta: { permissionKey: viewPermissionRefs.detergent, useManagerSiteId: true },
});

export const saveDetergent = (detergent) => ({
  type: API_REQUEST,
  callAPI: API.patchDetergent,
  action: SAVE_DETERGENT,
  data: detergent,
  callback: () => getDetergentPods(),
  closeModal: true,
});

export const addDetergent = (detergent) => ({
  type: API_REQUEST,
  callAPI: API.postDetergentTemp,
  data: detergent,
  action: ADD_DETERGENT_TEMP,
  callback: () => getDetergents(),
  closeModal: true,
});

export const addDetergents = (detergents) => ({
  type: API_REQUEST,
  callAPI: API.postDetergents,
  data: detergents,
  action: ADD_DETERGENTS_TEMP,
  callback: () => getDetergents(),
  closeModal: true,
});

export const deleteDetergent = (id) => ({
  type: API_REQUEST,
  callAPI: API.deleteDetergent,
  action: DELETE_DETERGENT_TEMP,
  data: { id },
  callback: () => getDetergents(),
  closeModal: true,
});
export const archiveDetergent = (id) => ({
  type: API_REQUEST,
  callAPI: API.archiveDetergent,
  action: ARCHIVE_DETERGENT,
  data: { id },
  callback: () => getDetergents(),
  closeModal: true,
});
export const getDetergent = (id) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentTemp,
  action: GET_DETERGENT_TEMP,
  data: { id },
});

export const getChartData = (query, signal) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentChartData,
  action: GET_CHART_DATA,
  data: { query, signal },
});

export const getDetergentReadings = (query, signal) => ({
  type: API_REQUEST,
  callAPI: API.getDetergentReadingsTemp,
  action: GET_DETERGENT_READINGS_TEMP,
  data: { query, signal },
});

export const addDetergentReading = (reading) => {
  return {
    type: API_REQUEST,
    callAPI: API.postDetergentReadingTemp,
    action: ADD_DETERGENT_READING_TEMP,
    data: reading,
    callback: () => getDetergentPods(),
    closeModal: true,
  };
};

export const removeReadings = (id) => ({ type: REMOVE_DETERGENT_READINGS, id });
export const setDetergentSelection = (rows) => ({ type: SET_DETERGENT_SELECTION, rows });
export const showChart = (chart) => ({
  type: SHOW_DETERGENT_SUMMARY_CHART,
  chart,
});
export const setCostPerCarChartFromDate = (date) => ({
  type: SET_COST_PER_CAR_CHART_FROM_DATE,
  date,
});
export const setCostPerCarChartUntilDate = (date) => ({
  type: SET_COST_PER_CAR_CHART_UNTIL_DATE,
  date,
});
export const setReadingsFromDate = (date) => ({
  type: SET_DETERGENT_READINGS_FROM_DATE_TEMP,
  date,
});
export const setReadingsUntilDate = (date) => ({
  type: SET_DETERGENT_READINGS_UNTIL_DATE_TEMP,
  date,
});

export const batchArchiveDetergents = (archivedDetergents) => ({
  type: API_REQUEST,
  callAPI: API.postBatchArchiveDetergents,
  data: archivedDetergents,
  action: ARCHIVE_DETERGENT,
  callback: () => getDetergents(),
  closeModal: true,
});

export const clearDetergentSelections = () => ({ type: CLEAR_DETERGENT_SELECTIONS });
