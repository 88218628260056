export const initialState = {
  isWashClub: false,
};

export function theme(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export const selectIsWashClub = (state) => state.theme.isWashClub;
