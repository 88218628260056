import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import baseChart from '../../../core/components/BaseChart';
import Loader from '../../../core/components/Loader';
import { getApiStatus } from '../../../core/utils';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);
interface Props {
  chartData: any;
}
export const DetergentsKpiBar = (barData: any): JSX.Element => {
  const [chartInfo, setChartInfo] = useState({ data: { datasets: [] }, options: {} });
  const [drawerPosition, setDrawerPosition] = useState({ x: 0, y: 0 });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [padding, setPadding] = useState(10);
  const loadingApiCall = useSelector((state) => getApiStatus(state, 'OVERVIEW_GET_DETERGENT_POD'));
  const barRef = useRef<any>();
  const intl = useIntl();
  const { labels, datasets } = barData.barData;
  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };
  const createChartData = () => {
    const options: any = {
      indexAxis: 'y',
      responsive: true,
      borderSkipped: false,
      maintainAspectRatio: false,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      customCanvasBackgroundColor: {
        color: '#96C4E0',
      },
      plugins: {
        customCanvasBackgroundColor: {
          color: '#96C4E0',
        },
        datalabels: {
          display: false,
        },
        title: {
          position: 'top',
          align: 'start',
          display: false,
          font: {
            size: 30,
          },
        },
        elements: {
          bar: {
            borderRadius: 5,
            pointStyle: 'circle',
          },
        },
        legend: {
          usePointStyle: 'true',
          pointStyle: 'circle',
          position: 'bottom',
          display: false,
          labels: {
            usePointStyle: 'true',
            pointStyle: 'circle',
            textAlign: 'center',
            boxWidth: 6,
            display: true,
            padding: padding,
          },
        },
      },
      scales: {
        x: {
          stacked: false,
          display: false,
        },
        y: {
          stacked: true,
          display: false,
        },
      },
    };
    const data: any = {
      labels,
      datasets,
    };
    setChartInfo({
      data,
      options: options,
    });
  };
  const triggerMobileView = (chart?: ChartJS | null) => {
    if (!chart) {
      return;
    }
    if (chart.width <= 550) {
      setPadding(17);
      chart.render();
    } else {
      setPadding(10);
      chart.render();
    }
  };
  const handleChartDraw = () => {
    if (!loadingApiCall && Object.keys(chartInfo.data).length) {
      return (
        <div>
          <div className="detergent-bar-heightt" onClick={toggleDrawer}>
            <Bar ref={barRef} options={chartInfo.options} data={chartInfo.data} />
          </div>
        </div>
      );
    }
    return <Loader />;
  };
  useEffect(createChartData, [barData.detergentData]);
  useEffect(() => {
    const chart = barRef.current;
    triggerMobileView(chart);
  }, []);

  const date = moment(barData.barData.lastUpdate?.createdAt);
  const formattedDateTime = date.format('MM/DD/YY');
  return (
    <div>
      <section className="detergent-bar-section">{handleChartDraw()}</section>
      {isDrawerOpen && (
        <div className="drawer" style={{ top: drawerPosition.y, left: drawerPosition.x }}>
          <div className="detergent-bar-label">
            {intl.formatMessage(
              { id: 'detergentUpdatedBy' },
              { date: formattedDateTime, creator: barData.barData.lastUpdate.creator }
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default baseChart(DetergentsKpiBar);
