import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { fieldRequired, requiredInput, Select } from '../../../core/formValidation';
import { Row, Col } from 'reactstrap';
import { washSelection, detergentSelection, colorSelect } from '../helpers';

const DetergentEditForm = ({
  products,
  intl,
  inventory,
  selectedProducts,
  handleProductChange,
  handleDetergentChange,
  selectedDetergent,
  detergents,
}) => {
  return (
    <div>
      <div>
        <Row>
          <Col>
            <label className="custom-modal-title">
              {intl.formatMessage({ id: 'editDetergentsDetails' })}
            </label>

            <div className="form-group">
              <Field
                className="form-control"
                name="name"
                id="name"
                component={() =>
                  detergentSelection({
                    detergents,
                    intl,
                    handleDetergentChange,
                    initialValues: selectedDetergent,
                  })
                }
                type="text"
                aria-describedby="descriptionHelp"
                placeholder="Enter name for detergent"
                validate={[fieldRequired]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="capacity">{intl.formatMessage({ id: 'washPlanProducts' })}</label>
            <div className="form-group">
              <Field
                name="detergentWashes"
                component={() =>
                  washSelection({
                    products,
                    intl,
                    selectedProducts,
                    handleProductChange,
                  })
                }
              />
            </div>
          </Col>
          <Col>
            <label htmlFor="capacity">{intl.formatMessage({ id: 'capacityGal' })}</label>
            <div className="form-group">
              <Field
                className="form-control"
                name="capacity"
                id="capacity"
                component={requiredInput}
                type="number"
                aria-describedby="descriptionHelp"
                placeholder="Capacity of the container"
                validate={[fieldRequired]}
              />
            </div>
          </Col>
          <Col>
            <label htmlFor="alarmLevel">{intl.formatMessage({ id: 'alarmGal' })}</label>
            <div className="form-group">
              <Field
                className="form-control"
                name="alarmLevel"
                id="alarmLevel"
                component="input"
                type="number"
                aria-describedby="descriptionHelp"
                placeholder="Alarm level"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="alarmLevel">{intl.formatMessage({ id: 'position' })}</label>
            <div className="form-group">
              <Field
                className="form-control"
                name="position"
                id="position"
                component="input"
                type="number"
                parse={(value) => Number(value)}
                aria-describedby="descriptionHelp"
                placeholder="enter"
              />
            </div>
          </Col>
          <Col>
            <label htmlFor="alarmLevel">{intl.formatMessage({ id: 'autoRorderLevel' })}</label>
            <div className="form-group">
              <Field
                className="form-control"
                name="reorderLevel"
                id="reorderLevel"
                component={requiredInput}
                type="string"
                aria-describedby="descriptionHelp"
                placeholder="Reorder Level"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="detergentColor">{intl.formatMessage({ id: 'detergentColor' })}</label>
            <div className="form-group">
              <Field
                className="form-control"
                name="detergentColor"
                id="detergentColor"
                component={colorSelect}
                type="text"
                aria-describedby="descriptionHelp"
                placeholder="detergent Color"
              />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label htmlFor="inventoryItem">
                {intl.formatMessage({ id: 'relatedInventoryItem' })}
              </label>
              <Field
                name="inventoryItem"
                id="inventoryItem"
                component={Select}
                className="form-control"
                parse={Number}
                onBlur={(e) => {
                  e.preventDefault();
                }}
              >
                <option value={-1} disabled>
                  {intl.formatMessage({ id: 'selectInventoryItem' })}
                </option>
                {inventory &&
                  inventory.map((inv, i) => (
                    <option key={i} value={inv.id}>
                      {inv.name}
                    </option>
                  ))}
              </Field>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

DetergentEditForm.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  inventory: PropTypes.arrayOf(PropTypes.shape({})),
  selectedProducts: PropTypes.arrayOf(PropTypes.shape({})),
  addNew: PropTypes.bool,
};

// TODO: add validator
const enhance = compose(injectIntl, reduxForm({ form: 'detergent' }));

export default enhance(DetergentEditForm);
