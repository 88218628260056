import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

export const WashTypeFilter = (props) => {
  const intl = useIntl();
  const handleChange = ({ target: { value } }) => {
    props.setFilter({
      ...props.currentFilter,
      currentStatus: value,
    });
  };

  return (
    <div className="form-group">
      <label>Wash Type</label>
      <select
        className="form-control"
        value={props.currentFilter.currentStatus}
        onChange={handleChange}
      >
        <option value="all">{intl.formatMessage({ id: 'all' })}</option>
        <option value="interior">{intl.formatMessage({ id: 'interior' })}</option>
        <option value="exterior">{intl.formatMessage({ id: 'exterior' })}</option>
      </select>
    </div>
  );
};

WashTypeFilter.defaultProps = {
  currentFilter: {
    currentStatus: 'All',
  },
};

WashTypeFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.shape({
    currentStatus: PropTypes.string.isRequired,
  }).isRequired,
};

export default WashTypeFilter;
