import taskMessages from './tasks';
import sideNavMessages from './sideNav';
import generalMessages from './general';
import inventoryMessages from './inventory';

const messages = {
  en: {
    ...taskMessages.en,
    ...sideNavMessages.en,
    ...generalMessages.en,
    ...inventoryMessages.en,

    fileUploadSuccess: 'File uploaded successfully',
    fileUploadFailure: 'File upload failed please try again',
    vendingSalesSuccess: 'Vending sales updated successfully',
    vendingSalesFailure: 'Vending sales update failed please try again',
    noResultsFound: 'No results found',
    'shifts.schedule.onetime': 'One time only',
    'shifts.schedule.weekly': 'Weekly on {weekdays}',
    'shifts.schedule.weekly.noSchedule': 'Weekly',
    powerbiError:
      '{error}, Please Refresh Your Web Browser. If This Issue Persists Contact Support.',
    uploadCSV: 'Upload CSV',
    invalidCSV: 'Invalid CSV file. Please check the headers and try again.',
    activeVehicleSubscriptions: 'Active Vehicle Subscriptions',
    missingHeaders: 'Missing Headers',
    invalidFileType: 'Invalid File Type',
    capacity: 'Capacity',
    currentLevel: 'Current Level',
    alarmLevel: 'Alarm Level',
    alarms: 'Alarms',
    uploadWeek: 'Upload Week*',
    runCount: 'Controller Count',
    popCount: 'POP Count',
    difference: 'Difference',
    adjustments: 'Adjustments',
    invaildProfitAndLossFile: 'Invalid File Structure, Please Use The Template Provided',
    noCSVFilesToPreview: 'No CSV files to preview.',
    loadingCSVContent: 'Loading CSV content...',
    previousFile: 'Previous File',
    nextFile: 'Next File',
    profitAndLostAdditionalColumns: 'Additional columns are not allowed.',
    profitAndLostNegative: 'Negative values are not allowed',
    priceWithoutTax: 'Price without tax',
    tax: 'Tax',
    total: 'Total',
    alerts: 'Alerts',
    noAlerts: 'Nothing new to display.',
    vendingSalesInstructions:
      '*Please rollover any missed weeks into the sales for the current week submission',
    overview: 'Overview',

    projectEnterName: 'Enter a name for your project',
    projectEnterDescription: 'Enter a description for your project',
    essential: 'Essential',
    essentialTasks: 'Essential Tasks',
    maintenanceTasks: 'Maintenance Tasks',
    totalWashCount: 'Total Wash Count',
    occupancyMeter: 'Occupancy Meter',
    whatsUp: "What's up",
    costPerCar: 'Cost Per Car',

    detergentLevels: 'Detergent Levels',
    washCounts: 'Wash Counts',
    addWashCounts: 'Add New Wash Counts',
    updateWashCounts: 'Update Current Wash Counts',
    monthlyCarCount: 'Monthly Car Count',
    rewashes: 'Rewashes',
    monthByMonth: 'Month by Month',
    showLess: 'Show Less',
    showMore: 'Show More',
    maintenance: 'Maintenance',
    project: 'Project',

    addProduct: 'Add Product',

    utilityNamePlaceholder: 'Enter description for the utility bill',
    utilityStartDatePlaceholder: 'Select start date',
    utilityEndDatePlaceholder: 'Select end date',
    utilityReadingPlaceholder: 'Enter reading',
    startReading: 'BOP Reading',
    endReading: 'EOP Reading',
    readingUnit: 'Reading unit',
    readingUnitShort: 'Unit',

    utilityAmountPlaceholder: 'Enter bill amount',
    currency: 'Currency',
    Water: 'Water',
    Electric: 'Electric',
    Gas: 'Gas',
    Sewer: 'Sewer',
    itemNumber: 'Item Number',
    installedAt: 'Date Installed',
    installedBy: 'Installed By',
    preferredStockLevel: 'Required Stock Level',
    stockLevel: 'Stock Level',
    stockOnHand: 'Stock on Hand',
    parts: 'Parts',
    part: 'Part',
    tools: 'Tools',
    tool: 'Tool',
    equipment: 'Equipment',
    customerName: 'Guest Name',
    comment: 'Comment',
    feedbackType: 'Feedback Type',
    actionTaken: 'Action Taken',
    priority: 'Priority',
    positive: 'Positive',
    negative: 'Negative',
    neutral: 'Neutral',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    noneNeeded: 'None Needed',
    resolved: 'Resolved',
    notYetResolved: 'Not Yet Resolved',
    employees: 'Employees',
    shifts: 'Shifts',
    employee: 'Employee',
    status: 'Status',
    customerEmail: 'Guest Email',
    timestamp: 'Timestamp',
    interval: 'Interval',
    adjustedWash: 'Wash',
    adjustWashCount: 'Add POS washes',
    enterDate: 'Enter Date',
    numberOfWashes: 'Number of washes',
    claim: 'Claim',

    'issue site': 'Issue site',
    'reporter site': 'Reported by site',

    AllOpen: 'All Open',
    AllClosed: 'All Closed',
    Incomplete: 'Incomplete',
    complete: 'Complete',
    NeedsAttention: 'Needs Attention',
    Investigating: 'Investigating',
    NeedsRepair: 'Needs Repair',
    UnderRepair: 'Under Repair',
    Repaired: 'Repaired',
    Resolved: 'Resolved',
    NotCaused: 'Not Caused',

    'Add part': 'Add part',

    addRewash: 'Add Rewash',
    editRewash: 'Edit Rewash',
    addWarrantyRequest: 'Add Warranty Request',
    editWarrantyRequest: 'Edit Warranty Request',
    addClaim: 'Add Claim',
    editClaim: 'Edit Claim',
    confirmReclamationDelete: 'Are you sure you want to delete reclamation',
    confirmReclamationArchive: 'Are you sure you want to archive reclamation',
    confirmProfitAndLossUpload: 'Is the selected statement period correct?',
    confirmReclamationUnarchive: 'Are you sure you want to unarchive reclamation',
    deleteReclamation: 'Delete reclamation',
    archiveReclamation: 'Archive reclamation',
    unarchiveReclamation: 'Unarchive reclamation',
    confirmUpload: 'Confirm Upload',
    addCustomerDetails: 'Add Customer Details',
    addIssueSite: 'Add issue site',

    declineIssueSiteTitle: 'Decline issue reported by another site?',
    declineNote: 'Decline note (optional)',
    claimReportedByAnotherSite: 'Claim was reported by another site.',
    issueSitePending: 'Issue site has not accepted this warranty request yet.',
    issueSiteDeclined: 'Issue site declined this warranty request.',
    issueSiteDeclinedWithNote: 'Issue site declined this warranty request with note: ',
    operation: 'Operation',
    lastCompleted: 'Last Completed',

    dueDate: 'Due Date',
    accomplished: 'Accomplished',
    notes: 'Notes',
    closeDrawer: 'Close drawer',
    maximizeDrawer: 'Maximize drawer',
    minimizeDrawer: 'Minimize drawer',

    customerDetailsAdded: 'Customer details successfully added!',
    addCarWashDetails: 'Add car wash details',
    continue: 'Continue',
    thankYou: 'Thank you!',
    returnTablet: 'You may now return the tablet.',
    note: 'Note',
    backToNotes: 'Back to notes',
    addNote: 'Add note',
    deleteNote: 'Delete note',
    deleteNoteConfirmation: 'Are you sure you want to delete this note?',

    partNumber: 'Part Number',
    installationDate: 'Installation date',
    currentCarCount: 'Current lifetime car count: {carCount}',
    registers: 'Registers',
    additionalRegisters: 'Additional Registers',
    washRegisters: 'Wash Registers',
    carCountAddress: 'Lifetime car count address',
    startingAddress: 'Starting Address',
    addAddress: 'Add Address',
    port: 'Port',
    lcPort: 'Lane Controller Port',
    mcpPort: 'MCP PLC Port',
    nmPort: 'Entrance Module Port',
    vcPort: 'Vacuum Controller Port',
    rocPort: 'RO Controller Port',
    host: 'Host',
    purchases: 'Purchases',
    totalDiscount: 'Total discount',
    repair: 'Repair',
    vendor: 'Vendor',
    quantity: 'Quantity',
    repairedBy: 'Repaired by',
    addRepairData: 'Add Repair Data',
    carPart: 'Car part',

    labor: 'Labor',
    warrantyRequests: 'Warranty requests',
    claimsReports: 'Reports',
    reportType: 'Report type',
    TotalCostPerDamageType: 'Total cost per damage type',
    SumOfDamageType: 'Sum of damage type',
    WarrantyRequestsByDamageArea: 'Warranty requests by damage area',
    ClaimsByDamageArea: 'Claims by damage area',
    DamageByMakeModelYear: 'Damage by make model year',
    ClaimsPerCarAndCostPerCar: 'Claims per car and cost per car',
    AreaOfDamage: 'Area of damage',
    WarrantyRequestsByVehicleModel: 'Warranty requests by vehicle model',
    ClaimsByVehicleModel: 'Claims by vehicle model',
    damageType: 'Damage type',
    grandTotal: 'Grand total',
    damagedArea: 'Damaged area',
    sumOfCost: 'Sum of cost',
    getRepaired: 'Get repaired',
    notCaused: 'Not caused',
    vehicleMake: 'Vehicle make',
    vehicleModel: 'Vehicle model',
    totalCost: 'Total cost',
    percentageWarranty: '% Warranty',
    divisionCarWashed: '/ Cars washed',
    carCount: 'Car count',
    StationaryAntenna: 'Stationary antenna',
    PowerAntenna: 'Power antenna',
    Wiper: 'Wiper',
    Mirror: 'Mirror',
    TrimOrMolding: 'Trim or molding',
    Scratch: 'Scratch',
    Bumper: 'Bumper',
    Windshield: 'Windshield',
    Wheel: 'Wheel',
    Tire: 'Tire',

    searchForVehicle: 'Search for vehicle',
    reports: 'Reports',
    statusChanged: 'Status changed',
    emailWillBeSent: 'An email with the Claim information will be sent to the customer.',
    statusChangedFromTo:
      'Status has been changed from "{currentStatusString}" to "{newStatusString}".',
    confirmFileArchive: 'Are you sure you want to archive file: "{fileName}" ?',
    archiveFile: 'Archive File',
    addAdjustment: 'Add Adjustment',
    operationName: 'Operation name',
    numAdjustments: 'Number of adjustments',
    searchForOperation: 'Search for operation',
    adjustment: 'Adjustment',
    newNumAdjustments: 'New number of adjustments',
    currentForTheDate: '(current for the date: {count})',
    adjustPOSWashes: 'Adjust POS Washes',
    showingCountsDate: 'Showing counts from {date}.',
    showingCountsFromTo: 'Showing counts from {from} to {until}.',
    week: 'Week',
    showingCountsFrom: 'Showing counts from ',
    to: 'to',

    addNotes: 'Add notes',
    product: 'Product',
    current: 'Current',
    newTotal: 'New Total',
    newAdjustment: 'New Adjustment',
    newPrimarySite: 'New primary site selected',
    newSiteSelected: 'New site selected',
    damageOptions: 'Damage Options',
    oneSite: 'Select a new site to update',
    todaysAdjustments: "Today's Adjustments",
    selectProduct: 'Select product',

    print: 'Print',
    totalTax: 'Total tax',
    rewashCauses: 'Rewash Causes',
    dirtyWheels: 'Dirty Wheels',
    dirtyRockerPanel: 'Dirty Rocker Panel',
    dirtyBackend: 'Dirty Backend',
    other: 'Other',
    serviceFailure: 'Service Failure',
    missedSpots: 'Missed Spots',
    bugs: 'Bugs',
    time: 'Time',
    rewashReason: 'Rewash Reason',
    settings: 'Settings',
    products: 'Products',
    category: 'Category',
    productName: 'Product Name',
    deleteProduct: 'Delete Product',
    deleteProductConfirmation: 'Are you sure you want to delete product "{name}"?',
    detergent: 'Detergent',
    detergentsTemp: 'Detergent Temp',
    volumeInGallons: 'Volume in gallons',
    gallons: 'Gallons',
    inStock: 'In Stock',
    requiredStock: 'Required Stock',
    relatedInventoryItem: 'Related Inventory Item',
    updateDetergentData: 'Update Detergent Data',
    upsertDetergentData: 'Upsert Detergent Data',
    dateOfPurchase: 'Date of Purchase',
    volume: 'Volume',
    validAt: 'Valid At',
    selectInventoryItem: 'Select Inventory Item',
    currentPrice: 'Current price:',
    currentVolume: 'Current volume:',
    canBeChangedInInventory: 'These can be changed in the inventory related to the detergent',
    inventoryDataForDetergentAndDate: 'Inventory Data for the selected detergent and date',
    container: 'Container',
    selectContainer: 'Select a container',
    quantityOfContainers: 'Quantity of containers',
    enterQuantityOfContainers: 'Enter quantity of containers',
    enterPrice: 'Enter price',
    enterANumber: 'Enter a number',
    chooseDate: 'Choose date',
    chooseForm: 'Choose Form',
    chooseFile: 'Choose File',
    updateFile: 'Update File',
    chooseWeek: 'Choose week',
    enterTotals: 'Enter totals',
    chooseAuthor: 'Choose Author',
    chooseStatementPeriod: 'Choose Statement Period',
    chooseSite: 'Choose Site',
    totalPrice: 'Total price',
    gallonsLeft: 'Gallons Left',
    editProduct: 'Edit Product',
    templateData: 'Template Data',
    loadTemplateData: 'Load Template Data',
    siteControlSettings: 'Site Control Settings',
    washCounter: 'Exterior Wash Counter',
    configure: 'Configure',
    lifetimeCarCount: 'Lifetime Car Count',
    configureWashCounter: 'Configure Wash Counter',
    configureInteriorWashCounter: 'Configure Interior Wash Counter',
    taskType: 'Task Type',
    taskPrefix: 'Task Prefix',
    teamMember: 'Team Member',
    teamMembers: 'Team Members',
    topPerformingSingleWashes: 'Top performing single washes',
    topPerformingSubscriptionWashes: 'Top performing subscription washes',
    cars: 'Cars',
    'sales/car': 'Sales/car',

    wash: 'Wash',
    containerSize: 'Container Size',
    historicalTankReadings: 'Historical Tank Readings',
    reading: 'Reading',
    detergentBased: 'Detergent Based',
    dateBased: 'Date Based',
    detergents: 'Detergents',
    addReading: 'Add Reading',
    addDetergent: 'Add Detergent',
    deleteDetergentConfirmation: 'Are you sure you want to delete detergent: {name}?',
    bailOutVehicle: 'Bail Out Vehicle',
    openGate: 'Open Gate',
    selectOperation: 'Select Operation',
    selectCategory: 'Select Category',
    defaultsToOperation: 'Defaults to Operation name',
    customProductName: 'Custom product name',
    customProductNameWarning:
      'Product name has to be identical to the Operation name if you want the Wash Count controller numbers to show up correctly. This is only for cases where the Operation exists.',
    showTopSingleWashes: 'Show Top Performing Single Washes',
    showTopSubscriptionWashes: 'Show Top Performing Subscription Washes',
    lastRead: 'Last Read',
    lastHourAddress: 'Last hour address',
    currentHourAddress: 'Current hour address',
    hourlyWashCounts: 'Hourly Wash Counts',
    hourlyWashCountsTable: 'Hourly Wash Counts Table',
    count: 'Count',
    payAsYouGo: 'Pay per Wash',
    resource: 'Resource',
    utilities: 'Utilities',
    addUtility: 'Add Utility Bill',
    editUtility: 'Edit Utility Bill',
    archiveUtility: 'Archive Utility Bill',
    archiveUtilityConfirmation: 'Are you sure you want to archive utility bill: {name}?',
    purchaseOrders: 'Purchase Orders',
    poNumber: 'PO Number',
    memo: 'Memo',
    trackingNumber: 'Tracking Number',
    addPurchaseOrder: 'Add Purchase Order',
    addVendor: 'Add Vendor',
    addVendingTotals: 'Add Vending Totals',
    addSitePAndL: 'Add Site P&Ls',
    downloadTemplate: 'Download CSV template',
    return: 'Return',
    receive: 'Receive',
    orderDate: 'Order Date',
    orderInfo: 'Order Info',
    orderedBy: 'Ordered By',
    archived: 'Archived',
    payPerWashDiscounts: 'Pay Per Wash Discounts',

    done: 'Done',
    applyToSelected: 'Apply to selected',
    summary: 'Summary',
    apply: 'Apply',
    close: 'Close',
    warning: 'Warning',

    siteDetails: 'Site Details',

    inventoryItemArchiveConfirmation:
      'Are you sure you want to archive the following Inventory Item: "{name}"?',
    essentialInventoryUpdateWarning:
      'This is essential inventory, updating this item will affect multiple sites.',
    essentialInventoryCreateWarning:
      'This is essential inventory, creating this item will affect multiple sites.',
    essentialInventoryDeleteWarning:
      'This is essential inventory, archiving these items will affect multiple sites.',
    essentialInventoryDeleteCheckbox: 'Archive all items associated with this essential inventory',
    archiveInventoryItem: 'Archive Inventory Item',
    archiveInventoryItems: 'Archive Inventory Items',
    editInventoryItem: 'Edit Inventory Item',
    updateStock: 'Update Stock',
    addInventoryItem: 'Add Inventory Item',
    addEssentialPart: 'Add Essential Part',
    saveInventory: 'Save Inventory Item',
    saveStockLevels: 'Save Stock Levels',
    enterPartNumber: 'Enter part number',
    enterPartName: 'Enter part name',
    enterItemDescription: 'Enter a brief item description',
    selectInstallationDate: 'Select installation date',
    enterStockOnHand: 'Enter stock on hand',
    enterPreferredStockLevel: 'Enter required stock level',
    enterVendorLink: 'Enter a link to the vendor',
    deleteRewash: 'Delete Rewash',
    deleteDetergent: 'Delete Detergent',
    addYear: 'Add Year',
    allModelYears: 'Available on all model years',
    modelYear: 'Model Year',
    fleetPayPerWash: 'Fleet Pay per Wash',
    fleetPayPerWashDiscounts: 'Fleet Pay Per Wash Discounts',
    payPerWash: 'Pay per Wash',
    undo: 'Undo',
    changes: 'Changes',
    currentChanges: 'Current Changes',
    addNewOption: 'Add New Option',
    siteModelYear: 'Site Model Year',
    siteModelYears: 'Site Model Years',
    siteOperationTypes: 'Site Operation Types',
    disableWorkLogs: 'Disable Work Logs',
    disableSiteManagerEditing: 'Disable Site Manager Editing',
    enableWashCounter: 'Enable Wash Counter',
    previewUpload: 'Preview Upload',
    previewFiles: 'Preview Files',
    manualEntries: 'Manual Entries (Registers)',
    readyToWash: 'ReadyToWash',
    pushButton: 'PushButton',
    addManualAddress: 'Add Manual Address',
    managerApp: 'Manager App',
    portal: 'Portal',
    ownersPortal: "Owner's Portal",
    login: 'Login',
    emailAddress: 'Email Address',
    password: 'Password',
    needHelp: 'Need help?',
    contact: 'Contact',
    manageUsers: 'Manage Users',
    jobTitle: 'Job Title',
    actions: 'Actions',
    userGroup: 'User Group',
    notFound: 'Not Found',
    employeeNotFound: "Something went wrong! Couldn't find employee!",
    selectEmployee: 'Select an employee',
    managerLoginNeeded: 'Manager App Login Needed',
    isCashier: 'Cashier',
    clearFields: 'Clear Fields',
    nextPage: 'Next Page',
    createNewUser: 'Create a New User',
    activeUsers: 'Active Users',
    createUser: 'Create User',
    loginCredentials: 'Login Credentials',
    accessToMultipleSites: 'Access to multiple sites',

    enterName: 'Enter Name',
    enterTitle: 'Enter Title',
    enterPhone: 'Enter Phone',
    emailExample: 'email@example.com',
    newEmployeeCreated: 'New Employee Created!',
    emailSent: 'Email sent with password instructions.',
    permissionSummary: 'Permission Summary',
    gotIt: 'Got it!',
    selectUserGroup: 'Select User Group',
    siteSelection: 'Site Selection',
    selectedSites: 'Selected Sites',
    selectSite: 'Select Site',
    forgotPassword: 'Forgot Password?',
    sendCode: 'Request password reset',
    backToSignIn: 'Back to Login',
    setPassword: 'Set New Password',
    confirmPassword: 'Confirm Password',

    invalidTokenMessage: 'Token is invalid',
    passwordMustMatch: 'Password must match',
    passwordUpdated: 'Password updated',
    invalidEmail: 'Error, please enter a valid email',
    passwordLength: 'Password must be six characters or longer',
    editUser: 'Edit User',
    userEdited: 'User Edited',
    confirm: 'Confirm',
    archiveUser: 'Archive user',
    includeArchived: 'Include archived',
    archived: 'Archived',
    actions: 'Actions',
    archiveUser: 'Archive User',
    unarchiveUser: 'Unarchive User',
    archiveConfirmation: 'Are you sure you want to archive user?',
    unarchiveConfirmation: 'Are you sure you want to unarchive user?',
    userArchived: 'User Archived',
    userUnarchived: 'User Unarchived',
    tableView: 'Table View',
    chartView: 'Chart View',
    compareLocal: 'Compare Local',
    sevenDayWash: 'Cars Washed - 7 day',
    claimCounts: 'Current Open Claims',
    popTotal: 'Capture Rate',
    percentClubsSold: '(% Clubs Sold)',
    ofAllSales: 'of All Sales',
    instaClubSales: 'InstaClubs Sold',
    totalClubsGained: 'Total Opportunities',
    uniqueClubsGained: '(Unique Washes)',
    laneOneSales: 'Capture Rate',
    mySalesReport: 'My Sales Report',
    selectedTotal: 'Selected Total',
    guestCancel: 'Guest Cancellations',
    cancelSurveyResults: 'Cancellation Survey Results',
    noCounts: 'No Counts',
    responsesGiven: 'Responses Given',
    noResponses: 'No Responses',
    responses: 'Responses',
    topResponses: 'Top {number} Responses',
    orderMore: 'Order More - Tommy Store',
    revenueComparison: 'Revenue Comparison',
    revenueSales: ' Sales',
    revenueBestDay: ' Best Day',
    manualEntriesPrograms: 'Program Registers',
    laneController: 'Lane Controller',
    entranceModule: 'Entrance Module',
    vacuumController: 'Vacuum Controller',
    roController: 'RO Controller',
    gwcController: 'GWC Controller',
    indusoftHmi: 'Indusoft HMI',
    toggleBestDayOn: 'Toggle Best Day: On',
    toggleBestDayOff: 'Toggle Best Day: Off',
    bestDay: 'Best Day ',
    mySiteSelector: 'My Site Selector',
    additionalSites: 'Additional Sites',
    myPrimarySite: 'Primary Site',
    removeAll: 'Remove All',
    totalRevenue: 'Total Revenue',
    limit: 'Limit',
    noSites: 'No Sites',
    yourCostPerCar: 'Variable Cost Per Car',
    goToPL: 'Go to P&L Reports',
    thisMonth: 'this month',
    costBreakdown: 'Cost Breakdown',
    claims: 'Claims',
    comingSoon: 'Coming Soon',
    costPerCarInfo:
      'Expenses(labor, utilities, detergents, maintenance, claims) / wash counts. Rolling 30-day comparison.',
    costPerCarInfoHeader: 'Cost Per Car Calculation',
    dateRangeLimiterEnabled: 'Enable Date Range Limiter',
    dateRangeLimiter: 'Date Range Limiter',
    goToTask: 'Go To Task',
    expand: 'Expand',
    collapse: 'Collapse',
    submit: 'Submit',
    posted: 'Posted',
    number: 'Number',
    feet: 'Feet',
    unit: 'Unit',
    moreDataRequired: 'More Data Required',
    addVideo: 'Add Video',
    videoEmbed: 'Video Embed',
    preview: 'Preview',
    test: 'Test',
    videoSupport: 'Supports Youtube or Vimeo URLs',
    customerPhoneNumber: 'Guest Phone Number',
    both: 'Both',
    originalView: 'Original View',
    analyticsView: 'Analytics View',
    interiorDetails: 'Express Interior Details',
    exterior: 'Exterior',
    interior: 'Express Interior',
    analytics: 'Analytics',
    addEmployee: 'Add Employee',
    interiorHourlyOnly: 'Only apply filter to Hourly Wash Counts',
    noSitesAvailable: 'No sites available',
    noSitesSelected: 'No sites selected',
    interiorController: 'Interior Controller',
    interiorWashCounter: 'Interior Wash Counter',
    useDashboardVpnSettings: 'Use Tommy Modbus Host/Port',
    tommyModbusPort: 'Tommy Modbus Port',
    tommyHost: 'Tommy Modbus Host',
    logout: 'Logout',
    record: 'Record',
    manageDetergents: 'Manage Detergents',
    addDetergentPod: 'Add Detergent Pod',
    detergentPod: 'Detergent Pod',
    detergentName: 'Detergent Name',
    detergentReadings: 'Detergent Readings',
    loadTommyStandard: 'Load Tommy Template',
    addNewPod: 'Add New Detergent Pod',
    editDetergentPod: 'Edit Detergent Pod',
    saveNewPod: 'Save New Pod',
    editDetergents: 'Edit Detergents',
    editDetails: 'Edit Details',
    title: 'Title',
    deletePod: 'Delete Pod',
    deleteForever: 'Delete Forever',
    deletePodConfirmation:
      'This action cannot be undone. All detergents and pod details will be deleted forever.',
    detergentReset: 'Detergent Template Reset',
    noPodsFound: 'No Pods Found',
    addNew: 'Add New Detergent',
    addReadings: 'Add Readings',
    saveReadings: 'Save Readings',
    gal: 'GAL',
    toggleScrollDirection: 'Toggle Scroll Direction',
    reorderLevel: 'Reorder Level',
    washProducts: 'Wash Products',
    relatedEquipment: 'Related Equipment',
    archive: 'Archive',
    detergentColor: 'Detergent Color',
    editDetergentsDetails: 'Edit Detergent Details',
    washPlanProducts: 'Wash Plans/Products',
    capacityGal: 'Capacity (gal)',
    alarmGal: 'Alarm (gal)',
    autoRorderLevel: 'Auto Reorder Level',
    chooseDetergentPod: 'Choose Detergent Pod',
    chooseDetergent: 'Choose Detergent',
    selectPlan: 'Select Plan',
    invalidPermissions: 'Invalid Permissions',
    addDetergentToDetergentPod: 'Add Container to Detergent Pod',
    addDetergentContainer: 'Add Container',
    editContainers: 'Edit Containers',
    editPod: 'Edit Pod',
    saveSettings: 'Save Settings',
    createDetergent: 'Create Detergent',
    removeDetergentFromDetergentPod:
      'Are you sure you want to remove detergents from the detergent pod?',
    addDetergents: 'Add New Detergent',
    editDetergent: 'Edit Detergent',
    addTask: 'Add Task',
    deleteTask: 'Delete Task',
    saveTask: 'Save Task',
    assignedTo: 'Assigned To',
    lastCompletedBy: 'Last Completed By',
    lastCompletedDate: 'Last Completed Date',
    howAreYourDetergentLevels: 'How are your detergent levels?',
    levelsLastRecorded: 'Levels Last Recorded by: {employee} on {date}',
    recordLevels: 'Record Levels',
    belowAlarm: 'Below Alarm - {percent}%',
    detergentUpdatedBy: 'Updated on {date}, by {creator}',

    subTitle: 'Subtitle',
    dueDateIntervalType: 'Due Date Interval Type',
    taskId: 'Task ID',
    intervalLevel: 'Select Interval Level',
    assignedEmployee: 'Assigned Employee',
    estimatedDueDate: 'Estimated Due Date',
    priority: 'Priority',

    notificationSettings: 'NOTIFICATION SETTINGS',
    notifications: 'Notifications',
    markAllAsRead: 'Mark all as read',
    notifyAsignee: 'Notify asignee?',
    sendToEmail: 'Send to Email',
    sendToSMS: 'Send to SMS',
    notifyOthers: 'Notify others?',
    resources: 'RESOURCES',
    partsNeeded: 'Parts needed',
    linkToParts: 'Link to Parts',

    clearData: 'Clear Data',
    siteSpecificDetails: 'SITE SPECIFIC DETAILS',
    relatesTo: 'relates to',
    chooseDependency: 'Choose Dependency',
    chooseTask: 'Choose Task',
    noLinkedTasks: 'No Linked Tasks',
    removeLink: 'Remove Link',
    complete: 'Complete',
    multipleTasks: 'multiple tasks',
    'All tasks': 'All Tasks',
    noStatus: 'No Status',
    inProgress: 'In Progress',
    completed: 'Completed',
    snoozed: 'Snoozed',
    incomplete: 'Incomplete',
    taskStatus: 'Task Status',
    maintenance: 'Maintenance',
    isStillActive: 'is still active',
    dateRangeError: 'Invalid date range',
    enterANumber: 'Enter a valid number',
    systemStatus: 'System Status',
    noRewashes: 'No Rewashes',
    noRewashReason: 'Needed Rewash',
    reason: 'Reason',
    dateIssued: 'Date Issued',
    operation: 'Operation',
    manageTeam: 'Manage Team',
    essentialParts: 'Essential Parts',
  },
  fi: {},
};

const getLocalizedMessages = (locale) => messages[locale] || messages.en;

export default getLocalizedMessages;
