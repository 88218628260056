import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import { checkErrors } from '../../../core/formValidation';
import * as actions from '../actions';
import DetergentAddForm from '../components/DetergentAddForm';
import DetergentModalDialog from '../../../core/components/DetergentModal';

export class AddDetergentDialog extends Component {
  static propTypes = {
    addDetergent: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    hideDialog: PropTypes.func.isRequired,
    errors: PropTypes.shape({}),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    inventory: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDetergent: null,
      selectedProducts: [],
    };
  }

  handleDetergentChange = (selectedDetergent) => {
    this.setState({ selectedDetergent });
  };

  handleProductChange = (selectedProducts) => {
    this.setState({ selectedProducts });
  };

  onReady = () => {
    this.props.triggerSubmit('detergent');
  };

  onSubmit = (values) => {
    if (this.props.addNew) {
      this.props.addDetergent(values);
    } else {
      const formValues = {
        ...values,
        detergentPodId: this.props.detergentPodId,
        detergentId: this.state.selectedDetergent.value,
        products: map(this.state.selectedProducts, (product) => ({ id: product.value })),
      };
      this.props.handleDetergentsToDetergentPod(formValues);
    }
    this.props.hideDialog();
  };

  render() {
    const { isOpen, products, inventory, addNew, detergents } = this.props;
    const disabled = checkErrors(this.props.errors);
    return (
      <DetergentModalDialog
        className={`${this.props.className}` || 'detergent-dialog'}
        title={this.props.title}
        onReady={this.onReady}
        isOpen={isOpen}
        close={this.props.hideDialog}
        disabled={disabled}
      >
        <DetergentAddForm
          className="detergent-dialog"
          onSubmit={this.onSubmit}
          products={products}
          inventory={inventory}
          detergents={detergents}
          selectedDetergent={this.state.selectedDetergent}
          selectedProducts={this.state.selectedProducts}
          handleProductChange={this.handleProductChange}
          handleDetergentChange={this.handleDetergentChange}
          addNew={addNew}
        />
      </DetergentModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('detergent');

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  return { errors };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, triggerSubmit: submit, hideDialog }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDetergentDialog);
