import { map } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormSyncErrors, submit } from 'redux-form';
import { hideDialog } from '../../../core/actions';
import ModalDialog from '../../../core/components/Modal';
import { checkErrors } from '../../../core/formValidation';
import * as actions from '../actions';
import DetergentEditForm from '../components/DetergentEditForm';
import { selectDetergentInEdit } from '../reducer';

class EditDetergentDialog extends Component {
  static propTypes = {
    saveDetergent: PropTypes.func.isRequired,
    triggerSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    hideDialog: PropTypes.func.isRequired,
    initialDetergent: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    products: PropTypes.arrayOf(PropTypes.shape({})),
    inventory: PropTypes.arrayOf(PropTypes.shape({})),
  };

  onReady = () => {
    this.props.triggerSubmit('detergent');
  };

  // TODO: manage validationErrors
  // TODO: create new detergent instance out of the values and pass that?
  onSubmit = (values) => {
    this.props.saveDetergent(values);
  };

  render() {
    const { isOpen, initialDetergent, products, inventory } = this.props;
    const submitDisabled = checkErrors(this.props.errors);

    if (initialDetergent) {
      if (initialDetergent.inventory) {
        initialDetergent.inventoryItem = initialDetergent.inventory.id;
      } else {
        initialDetergent.inventoryItem = -1;
      }
    }
    return (
      <ModalDialog
        title="Edit Detergent"
        onReady={this.onReady}
        isOpen={isOpen}
        close={this.props.hideDialog}
        disabled={submitDisabled}
      >
        <DetergentEditForm
          onSubmit={this.onSubmit}
          initialValues={initialDetergent}
          products={products}
          inventory={inventory}
          selectedProducts={initialDetergent ? map(initialDetergent.products, 'id') : []}
          enableReinitialize
        />
      </ModalDialog>
    );
  }
}

const getErrors = getFormSyncErrors('detergent');

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  return {
    initialDetergent: selectDetergentInEdit(state),
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      triggerSubmit: submit,
      hideDialog,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDetergentDialog);
