import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
interface Props {
  predefinedColors: string[];
  value: string;
  onChange: any;
}
export const ColorPicker = (props: Props): JSX.Element => {
  const [selectedColor, setSelectedColor] = useState(props.value);
  const [customColor, setCustomColor] = useState('#ffffff');
  const [showCustomColor, setShowCustomColor] = useState(false);
  const handleColorChange = (color: string) => {
    setSelectedColor(color);
    props.onChange(color);
  };
  const handleCustomColorChange = (color: string) => {
    setCustomColor(color);
    props.onChange(color);
  };
  useEffect(() => {
    if (!props.predefinedColors.includes(props.value)) {
      setCustomColor(props.value);
      props.onChange(props.value);
    }
  }, [props.value]);
  return (
    <div>
      <div>
        <div className="color-options" style={{ display: 'inline-flex' }}>
          {props.predefinedColors.map((color, index) => (
            <div
              key={index}
              className={`color-option ${props.value === color ? 'selected' : ''}`}
              style={{ backgroundColor: color }}
              onClick={() => handleColorChange(color)}
            ></div>
          ))}
          <div
            onClick={() => setShowCustomColor(!showCustomColor)}
            className={`color-option ${props.value === customColor ? 'selected' : ''}`}
            style={{ backgroundColor: customColor }}
          >
            {!customColor && <i className="icon icon-plus add-color-icon" />}
          </div>
          {showCustomColor && (
            <div>
              <div className="color-picker-actions" style={{ display: 'grid' }}>
                <button
                  className="btn btn-success btn-sm"
                  style={{ borderRadius: '4px' }}
                  onClick={() => {
                    props.onChange(customColor);
                    setShowCustomColor(false);
                  }}
                >
                  Save
                </button>
              </div>
              <HexColorPicker
                color={selectedColor}
                onChange={(color: string) => handleCustomColorChange(color)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ColorPicker;
