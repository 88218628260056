// TODO: read customer theme from url, and move styles to /themes
import * as storage from '../core/storage';
import logo from '../images/tommys_express_car_wash_logo_oval.png';
import washClubLogo from '../images/wash_club_car_wash_logo.png';

const getInitialState = () => {
  const jwtToken = storage.getJwtToken();
  const refreshToken = storage.getRefreshToken();
  const selectedSite = storage.getSelectedSite();
  const hostname = window.location.hostname;
  const port = window.location.port;
  const isWashClub =
    (hostname === 'localhost' && port === '3000') ||
    hostname === 'portal.wash.club' ||
    hostname === 'portal.staging-wash.club' ||
    hostname === 'portal.dev-wash.club';
  return {
    theme: {
      logo: isWashClub ? washClubLogo : logo,
      hostName: window.location.hostname,
      isWashClub,
    },
    auth: {
      jwtToken,
      refreshToken,
      selectedSite,
    },
  };
};

const hydrateStore = (additionalState) => {
  return {
    ...getInitialState(),
    ...additionalState,
  };
};

export default hydrateStore;
