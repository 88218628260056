import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import Permission, { RoleTypes } from '../../../core/components/Permission';
import { fieldRequired, requiredSelect } from '../../../core/formValidation';
import Loader from '../../../core/components/Loader';

export const UpdateSettingsForm = ({
  initialValues,
  siteModelYears,
  siteOperationTypes,
  formatMessage,
  role,
  isLoading,
  isUpdating,
}) => {
  const siteSettings = initialValues;
  const siteModelYearOptions = siteModelYears.map((modelYear) => ({
    text: modelYear.year,
    value: modelYear.id,
  }));
  siteModelYearOptions.unshift({ text: '', value: null });
  const operationTypeOptions = siteOperationTypes.map((operation) => ({
    text: operation.name,
    value: operation.id,
  }));
  operationTypeOptions.unshift({ text: '', value: null });

  return isLoading || isUpdating ? (
    <Loader />
  ) : (
    <div data-testid="updateSettingsForm">
      <div data-testid="disableWorkLogs" className="form-check">
        <label className="form-check-label">
          <Field
            name="workLogsDisabled"
            component="input"
            className="form-check-input"
            type="checkbox"
          />{' '}
          {formatMessage({ id: 'disableWorkLogs' })}
        </label>
      </div>
      <div
        data-testid="disableSiteManagerEditing"
        className="form-check"
        style={{ marginBottom: '0' }}
      >
        <label className="form-check-label">
          <Field
            name="managerAccessDisabled"
            component="input"
            className="form-check-input"
            type="checkbox"
          />{' '}
          {formatMessage({ id: 'disableSiteManagerEditing' })}
        </label>
      </div>
      <div data-testid="enableWashCounter" className="form-check" style={{ marginBottom: '.5rem' }}>
        <label className="form-check-label">
          <Field
            name="washCounterEnabled"
            component="input"
            value={siteSettings?.washCountEnabled || false}
            className="form-check-input"
            type="checkbox"
          />{' '}
          {formatMessage({ id: 'enableWashCounter' })}
        </label>
      </div>
      <div data-testid="enableVpnGroup" className="form-check" style={{ marginBottom: '.5rem' }}>
        <label className="form-check-label">
          <Field
            name="vpnGroupEnabled"
            component="input"
            value={siteSettings?.vpnGroupEnabled || false}
            className="form-check-input"
            type="checkbox"
          />{' '}
          {formatMessage({ id: 'useDashboardVpnSettings' })}
        </label>
      </div>
      <div className="form-check" style={{ marginBottom: '.5rem' }}>
        <label className="form-check-label">
          <Field
            name="dateRangeLimiterEnabled"
            component="input"
            value={siteSettings?.dateRangeLimiterEnabled || false}
            className="form-check-input"
            type="checkbox"
          />{' '}
          {formatMessage({ id: 'dateRangeLimiterEnabled' })}
        </label>
      </div>
      <Permission role={role} minRole={RoleTypes.OperatorGroupAdmin}>
        <div className="form-group" style={{ marginBottom: '.5rem' }}>
          <label className="form-check-label">
            {formatMessage({ id: 'siteOperationTypes' })}
            <Field
              id="operationTypeId"
              name="operationTypeId"
              data-testid="operationTypeId"
              component={requiredSelect}
              options={operationTypeOptions}
              parse={(value) => value && Number(value)}
              validate={[fieldRequired]}
              onBlur={(e) => {
                e.preventDefault();
              }}
            />
          </label>
        </div>
        <div className="form-group" style={{ marginBottom: '.5rem' }}>
          <label className="form-check-label">
            {formatMessage({ id: 'siteModelYears' })}
            <Field
              id="modelYearId"
              name="modelYearId"
              data-testid="modelYearId"
              component={requiredSelect}
              options={siteModelYearOptions}
              parse={(value) => value && Number(value)}
              validate={[fieldRequired]}
              onBlur={(e) => {
                e.preventDefault();
              }}
            />
          </label>
        </div>
      </Permission>
    </div>
  );
};

UpdateSettingsForm.propTypes = {
  modelYearId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operationTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  siteOperationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  siteModelYears: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      year: PropTypes.string,
    })
  ),
  role: PropTypes.string.isRequired,
};

const selector = formValueSelector('settings');
const enhance = compose(
  connect(
    (state) => ({
      modelYearId: selector(state, 'modelYearId'),
      operationTypeId: selector(state, 'operationTypeId'),
    }),
    null
  ),
  reduxForm({ form: 'settings', enableReinitialize: true })
);

export default enhance(UpdateSettingsForm);
