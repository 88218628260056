import React from 'react';
import { useIntl } from 'react-intl';
import { useAppContext } from '../../../providers/AppProvider';
import '../../../styles/landing-page-header.scss';

const LoginHeader = () => {
  const { isWashClub, theme } = useAppContext();
  const intl = useIntl();
  return (
    <header className="landing-page-header-container">
      <img className="landing-page-header-logo" src={theme.logo} alt="logo" />
      <h1 className="landing-page-header-text">
        {isWashClub
          ? intl.formatMessage({ id: 'ownersPortal' })
          : intl.formatMessage({ id: 'managerApp' })}
      </h1>
    </header>
  );
};

export default LoginHeader;
