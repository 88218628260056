import { NavLink, useLocation } from 'react-router-dom';
import { UserGroupPermissions } from './UserGroupPermissions';
import { useAppContext } from '../../providers/AppProvider';
import VisualScroll from './VisualScroll';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

const SideMenu = ({ menuItems, isOpen, logout, setNavState, mobileBreakpoint }) => {
  const intl = useIntl();
  const params = useLocation();
  const disableSideNav = params.pathname.split('/')[1] === 'site-selection' ? true : false;
  const { theme } = useAppContext();
  const toggle = () => {
    setNavState(!isOpen);
  };

  useEffect(() => {
    if (disableSideNav) {
      setNavState(false);
    }
  }, []);

  const closeMenu = () => {
    if (window.innerWidth <= mobileBreakpoint) {
      setNavState(false);
    }
  };

  const checkActiveMenuItem = (to) => (match, location) => {
    const mainLocation = to.split('/')[2];
    const currentLocation = location.pathname.split('/')[2];
    return mainLocation === currentLocation;
  };

  const links = disableSideNav
    ? menuItems.map((i) => {
        return (
          <UserGroupPermissions key={i.to} itemRef={i.permissionRef}>
            <li className={i.className}>
              <NavLink
                to="/site-selection"
                activeClassName="disabled"
                onClick={closeMenu}
                title={intl.formatMessage({ id: i.label })}
              >
                <span className={`icon ${i.icon}`} />
                <span className="label">{intl.formatMessage({ id: i.label })}</span>
              </NavLink>
            </li>
          </UserGroupPermissions>
        );
      })
    : menuItems.map((i) => {
        return (
          <UserGroupPermissions key={i.to} itemRef={i.permissionRef}>
            <li className={i.className}>
              <NavLink
                to={i.to}
                activeClassName="active"
                isActive={checkActiveMenuItem(i.to)}
                onClick={closeMenu}
                title={intl.formatMessage({ id: i.label })}
              >
                <span className={`icon ${i.icon}`} />
                <span className="label">{intl.formatMessage({ id: i.label })}</span>
              </NavLink>
            </li>
          </UserGroupPermissions>
        );
      });

  return (
    <nav className={`main ${isOpen ? 'expanded' : ''}`}>
      <div className="top">
        <img src={theme.logo} alt="logo" className="logo" />
        <button disabled={disableSideNav} onClick={toggle} className="nav-toggle">
          <i className="uil uil-bars"></i>
          <i className="uil uil-angle-left"></i>
        </button>
      </div>
      <VisualScroll classes="middle">
        <ul>{links}</ul>
      </VisualScroll>
      <div className="bottom">
        <div className="responsive-link">
          <button className="username" onClick={logout}>
            <span className="icon icon-log-out" />
            <span>{isOpen ? intl.formatMessage({ id: 'signOut' }) : ''}</span>
          </button>
        </div>
      </div>
      <div className="overlay" onClick={closeMenu} title="Click to close menu"></div>
    </nav>
  );
};

export default SideMenu;
