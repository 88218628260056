import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

interface FileObject {
  siteId: number;
  file: File;
}

interface FilePreviewModalProps {
  files: FileObject[];
  text: string;
  fileAddedCallback: (fileObject: FileObject) => void; // Accept callback prop
}

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({ files, text }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [csvContent, setCsvContent] = useState<string[][]>([]);

  const intl = useIntl();

  useEffect(() => {
    const file = files[currentIndex];
    if (file && file.file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const textInFile = e.target?.result as string;
        const rows = textInFile.split('\n').map((row) => row.split(',')); // Simple CSV parsing
        setCsvContent(rows);
      };
      reader.readAsText(file.file);
    }
  }, [currentIndex, files]);

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? files.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev === files.length - 1 ? 0 : prev + 1));
  };

  if (files.length === 0) return <p>{intl.formatMessage({ id: 'noCSVFilesToPreview' })}</p>;

  return (
    <div>
      <h3>{text}</h3>
      <p>
        {' '}
        {files[currentIndex].file.name} (Site ID: {files[currentIndex].siteId})
      </p>
      <div className="preview-button-container">
        <button
          //style={urls.length < 2 ? { display: 'none' } : {}}
          className="btn btn-secondary button"
          onClick={prevSlide}
        >
          <i className="icon icon-arrow-left " />
          {intl.formatMessage({ id: 'previousFile' })}
        </button>
        <span className="page-indicator">
          {currentIndex + 1} / {files.length}
        </span>
        <button
          //style={urls.length < 2 ? { display: 'none' } : {}}
          className="btn btn-secondary button"
          onClick={nextSlide}
        >
          {intl.formatMessage({ id: 'nextFile' })}
          <i className="icon icon-arrow-right" />
        </button>
      </div>
      <div
        style={{
          maxWidth: '120%',
          overflow: 'auto',
        }}
      >
        {csvContent.length > 0 ? (
          <table>
            <thead>
              <tr>
                {csvContent[0].map((header, index) => (
                  <th key={index} style={{ border: '1px solid #ddd', padding: '5px' }}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {csvContent.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} style={{ border: '1px solid #ddd', padding: '5px' }}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{intl.formatMessage({ id: 'loadingCSVContent' })}</p>
        )}
      </div>
    </div>
  );
};

export default FilePreviewModal;
