import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSite } from '../../../store/authReducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import { getDetergentPods, getDetergents } from '../actions';
import DetergentsKpi from './DetergentsKpi';
import {
  selectDetergentContainers,
  selectDetergentViewDataContainers,
} from '../../detergentsTemp/reducer';
interface Props {
  fromDate: any;
  untilDate: any;
}
const DetergentsKpiChartDataGetter = (props: Props) => {
  const dispatch = useDispatch();
  const detergentContainer = useSelector(selectDetergentContainers);
  const claimCounts = useSelector(selectDetergentViewDataContainers);
  const primarySiteId = useSelector(selectSite);
  const multiSiteSelection = useSelector(selectMultiSiteSelection);
  const fetchDetergentPods = () => {
    dispatch(getDetergentPods(props.fromDate, props.untilDate));
  };
  useEffect(fetchDetergentPods, [
    props.fromDate,
    props.untilDate,
    primarySiteId,
    multiSiteSelection,
  ]);
  return <DetergentsKpi chartData={claimCounts} detergentContainer={detergentContainer} />;
};
export default DetergentsKpiChartDataGetter;
