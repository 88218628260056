import { map } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import DataTable from '../../../core/components/DataTable';
import Loader from '../../../core/components/Loader';
import { getApiStatus } from '../../../core/utils';

const HourlyWashCountsTable = ({ intl, hourlyWashCounts, classNames, washTypeFilter }) => {
  const apiIsLoading = useSelector((state) => getApiStatus(state, 'FETCH_HOURLY_WASH_COUNTS'));
  const headers = [
    { name: intl.formatMessage({ id: 'time' }) },
    { name: intl.formatMessage({ id: 'count' }) },
  ];

  const getTableData = () => {
    return map(hourlyWashCounts, (hourlyCounts, hour) => {
      const count =
        washTypeFilter === 'interior'
          ? hourlyCounts.interiorCountsBySiteId || 0
          : washTypeFilter === 'exterior'
          ? hourlyCounts.exteriorCountsBySiteId || 0
          : hourlyCounts.count || 0;

      return {
        _id: hour,

        hour: moment(hour, 'hour').format('ha'),
        count,
      };
    });
  };

  const initializeDataTable = () => {
    return {
      headers,
      data: getTableData(),
      addedClass: 'mb-0',
    };
  };

  return (
    <div className={`wash-chart hourly-counts mt-3 shadowed ${classNames}`}>
      {!apiIsLoading ? (
        <span>
          <div className="wash-chart-label">{intl.formatMessage({ id: 'hourlyWashCounts' })}</div>
          <DataTable {...initializeDataTable()} />
        </span>
      ) : (
        <Loader />
      )}
    </div>
  );
};

HourlyWashCountsTable.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  hourlyWashCounts: PropTypes.shape({}).isRequired,
  classNames: PropTypes.string,
};

export default HourlyWashCountsTable;
