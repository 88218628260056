import classNames from 'classnames';
import { find, result } from 'lodash';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from '../../../providers/AppProvider';
import { fetchSites } from '../../../core/actions';
import Loader from '../../../core/components/Loader';
import { selectSite } from '../../../store/authReducer';
import {
  selectMultiSiteSelectionExternalIds,
  selectSiteName,
  selectSites,
} from '../../site-selection/reducer';
import { fetchReport, setAnalyticsPage, setPermissionLevel, GET_REPORT } from '../actions';
import NavTabs from '../components/NavTabs';
import PermissionViewButtons from '../components/PermissionViewButtons';
import Report from '../components/ReportFrame';
import setFilter from '../helpers';
import { PowerBiView, UserReportViewType } from '../models';
import {
  selectEmbedToken,
  selectEmbedUrl,
  selectInitialPage,
  selectPages,
  selectPermission,
  selectRedirectedPage,
  selectReportId,
  selectInitialTab,
} from '../reducer';
import { getApiStatus } from '../../../core/utils';

export const PowerBiReport = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const loading = useSelector((state) => getApiStatus(state, GET_REPORT));
  const { isWashClub } = useAppContext();
  // subscribe to the store
  const key = isWashClub ? 'Manager-Analytics-WashClub' : 'Manager-Analytics';
  const accessToken = useSelector((state) => selectEmbedToken(state, key));
  const embedUrl = useSelector((state) => selectEmbedUrl(state, key));
  const reportId = useSelector((state) => selectReportId(state, key));
  const pages = useSelector((state) => selectPages(state, key));
  const site = useSelector((state) => selectSiteName(state));
  const sites = useSelector((state) => selectSites(state));
  const currentSite = useSelector((state) => selectSite(state));
  const permissionLevel = useSelector((state) => selectPermission(state, key));
  const initialPage = useSelector((state) => selectInitialPage(state, key));
  const redirectedPage = useSelector((state) => selectRedirectedPage(state, key));
  const multiSiteExtIds = useSelector((state) => selectMultiSiteSelectionExternalIds(state));
  const initialTab = useSelector((state) => selectInitialTab(state));
  // local state
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageLoaded, setPageLoaded] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [view, setView] = useState(PowerBiView.Single);
  const [containsSingle, setContainsSingle] = useState(true);

  const reportRef = useRef(null);

  const defaultErrorHandler = useCallback(
    (err) => {
      setPageLoaded(false);
      setError(true);
      setErrorMessage(
        intl.formatMessage(
          { id: 'powerbiError' },
          { error: err?.detailedMessage || 'Error Occurred' }
        )
      );
    },
    [intl]
  );

  const userReportFilters = useCallback(() => {
    const currentSelectedSite = find(sites, (s) => s.id === Number(currentSite));

    if (view === PowerBiView.Single) {
      const conditions = [
        {
          operator: 'Is',
          value: isWashClub ? currentSelectedSite.extId : currentSelectedSite.externalId,
        },
      ];

      reportRef.current.setFilters([setFilter(conditions, isWashClub)]);
    } else {
      handleFilterRemoval();
    }
  }, [view, sites, currentSite, defaultErrorHandler]);

  const handleReport = useCallback(
    (report) => {
      if (!reportRef.current) {
        if (!report) {
          defaultErrorHandler();
        }
        reportRef.current = report;
      }

      if (report && report.iframe && report.iframe.contentWindow) {
        userReportFilters();
      }
    },
    [userReportFilters, defaultErrorHandler]
  );

  const initializePageLoaded = useCallback(() => {
    const multiInitialPage = result(
      find(pages, (page) => page.displayName.includes('Multi')),
      'name'
    );
    const redirectedPageInfo = pages.find((page) => page.displayName.includes(redirectedPage));

    setPageLoaded(true);

    if (!initialPage) {
      setView(PowerBiView.Multi);
      setContainsSingle(false);
      setCurrentPage(multiInitialPage);
    } else if (redirectedPageInfo) {
      setCurrentPage(redirectedPageInfo.name);
    } else {
      setCurrentPage(initialPage);
    }

    return multiInitialPage;
  }, [pages, initialPage, redirectedPage]);

  // ComponentDidMount equivalent
  useEffect(() => {
    if (accessToken) {
      const multiInitialPage = initializePageLoaded();
      if (multiInitialPage) {
        dispatch(setPermissionLevel(UserReportViewType.Multi));
      } else {
        dispatch(setPermissionLevel(UserReportViewType.Single));
      }
      dispatch(setAnalyticsPage(null));
    }

    if (initialTab === 'Multi') {
      setView(PowerBiView.Multi);
    }

    dispatch(fetchSites());
  }, []);

  // ComponentDidUpdate equivalents
  useEffect(() => {
    if (reportRef.current) {
      userReportFilters();
    }
  }, [view, site, multiSiteExtIds, userReportFilters]);

  useEffect(() => {
    if (reportRef.current && currentPage) {
      reportRef.current.setPage(currentPage);
    }
  }, [currentPage]);

  const handleFilterRemoval = () => {
    if (reportRef.current) {
      reportRef.current.removeFilters();
    } else {
      defaultErrorHandler({
        detailedMessage: 'Something went wrong with filtering',
      });
    }
  };

  const changeView = (newView) => {
    const initialMultiPage = result(
      find(pages, (page) => page.displayName.includes(PowerBiView.Multi)),
      'name'
    );

    setCurrentPage(newView === PowerBiView.Single ? initialPage : initialMultiPage);
    setView(newView);
  };

  const getReportComponent = (reloadedPage = null) => {
    return (
      <Report
        accessToken={accessToken}
        embedUrl={embedUrl}
        embedId={reportId}
        pageName={reloadedPage ?? currentPage}
        onLoad={handleReport}
      />
    );
  };

  const createNavTabs = (reloadedPages = null) => {
    return (
      <NavTabs
        userView={view}
        pages={reloadedPages ? reloadedPages : pages}
        currentPage={currentPage}
        setCurrentPage={(name) => {
          setCurrentPage(name);
          setError(false);
          setPageLoaded(true);
        }}
      />
    );
  };

  const showErrorMessage = () => {
    return <div className="powerbi-error">{errorMessage}</div>;
  };

  // if webpage has been reloaded, set the page to the initial page
  useEffect(() => {
    if (pages && pages.length > 0 && currentPage === null) {
      const singlePages = pages.filter((page) => page.displayName.includes('Single'));
      createNavTabs(singlePages);
      getReportComponent(singlePages[0].name);
      setCurrentPage(singlePages[0].name);
    }
  }, [pages, reload, pageLoaded]);

  return (
    <div className={classNames('container-fluid')}>
      {!reload && !loading && pages && currentPage && (
        <>
          <PermissionViewButtons
            permissionLevel={permissionLevel}
            view={view}
            changeView={changeView}
            includesSingle={containsSingle}
          />
          <section className={classNames('page-main-content', 'powerbi-container')}>
            <div className="powerbi-tab-container">
              <div className="powerbi-tab-scroll">{createNavTabs()}</div>
            </div>
            {error ? showErrorMessage() : getReportComponent()}
          </section>
        </>
      )}
    </div>
  );
};

export default PowerBiReport;
