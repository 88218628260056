import { useIntl } from 'react-intl';
import baseChart from '../../../core/components/BaseChart';
import Loader from '../../../core/components/Loader';
import { DetergentsKpiBar } from './DetergentsKpiBar';
import { useHistory } from 'react-router';
import React from 'react';
interface Props {
  detergentContainer: any;
  chartData: any;
}
export const DetergentsKpi = (props: Props): JSX.Element => {
  const history = useHistory();
  const pushToDetergents = () => {
    history.push('detergents');
  };
  const intl = useIntl();
  const borderRadius = 100;
  const borderRadiusAllCorners = {
    topLeft: borderRadius,
    topRight: borderRadius,
    bottomLeft: borderRadius,
    bottomRight: borderRadius,
  };
  const lightenColor = (color: string, percent: number) => {
    const lighten = (c: number) => Math.min(255, c + Math.floor(((255 - c) * percent) / 100));
    const r = lighten(parseInt(color.slice(1, 3), 16));
    const g = lighten(parseInt(color.slice(3, 5), 16));
    const b = lighten(parseInt(color.slice(5, 7), 16));
    return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
  };
  const detergentData = props.chartData.map((detergent: any) => {
    const currentLabel = `${detergent.label}`;
    const capacityLabel = `${detergent.label} (Capacity)`;
    const flattenedArray = props.detergentContainer.flat();
    const desiredId = detergent.id;
    const foundObject = flattenedArray.find((obj: { id: any }) => obj.id === desiredId);
    const lastUpdate = foundObject ? foundObject.latestReading : null;
    const currentDataset = {
      label: detergent.label,
      data: [parseFloat(detergent.currentValue)],
      backgroundColor: detergent.detergentColor,
      borderRadius: borderRadiusAllCorners,
      borderWidth: 1,
    };
    const capacityDataset = {
      label: intl.formatMessage({ id: 'capacity' }),
      data: [parseFloat(detergent.capacity)],
      backgroundColor:
        detergent.currentValue < detergent.alarmLevel
          ? '#F7E4E5'
          : lightenColor(detergent.detergentColor, 85),
      borderRadius: borderRadiusAllCorners,
      borderWidth: 1,
    };
    const percent = Math.round(
      (parseFloat(detergent.currentValue) / parseFloat(detergent.capacity)) * 100
    );
    return {
      labels: [currentLabel, capacityLabel],
      datasets: [currentDataset, capacityDataset],
      percent: percent,
      lastUpdate: lastUpdate,
    };
  });
  const handleChartDraw = () => {
    if (detergentData.length > 0) {
      return (
        <div>
          <div className="col-lg-12 col-md-12 col-sm-12"></div>
          {detergentData.map((barData: any, index: any) => {
            const current = parseFloat(barData.datasets[0].data[0]);
            const capacity = parseFloat(barData.datasets[1].data[0]);
            const alarmLevel = parseFloat(props.chartData[index].alarmLevel);
            const isBelowAlarm = current < alarmLevel;
            const percentageDiff = (Math.abs(current - alarmLevel) / capacity) * 100;
            const roundedPercentage = percentageDiff.toFixed(0);
            return (
              <div className="detergent-bar-height" key={index}>
                <div className="detergent-kpi-container">
                  <div className="detergent-bar-label">
                    {barData.labels[0]}
                    {isBelowAlarm && (
                      <span className="detergent-bar-alarm">
                        {intl.formatMessage({ id: 'belowAlarm' }, { percent: roundedPercentage })}
                      </span>
                    )}
                  </div>
                  <div className="detergent-bar-percent">{barData.percent}%</div>
                </div>
                <DetergentsKpiBar barData={barData} />
              </div>
            );
          })}
        </div>
      );
    }
    return <Loader />;
  };
  return (
    <div>
      <section className="container-fluid overview-chart-card">
        <div className="chart-title-section">
          <div className="chart-title">{intl.formatMessage({ id: 'detergentLevels' })}</div>
          <button
            className="btn btn-outline-secondary drs-button-shadow overview-chart-view-button"
            onClick={() => pushToDetergents()}
          >
            {intl.formatMessage({ id: 'recordLevels' })}
          </button>
          <div className="chart-sub-title">
            {intl.formatMessage({ id: 'howAreYourDetergentLevels' })}
          </div>
        </div>
        {handleChartDraw()}
      </section>
    </div>
  );
};
export default baseChart(DetergentsKpi);
