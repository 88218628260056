import { invokeMap, isEqual, map, values } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import useAbortController from '../../../core/abortController';
import Tabs from '../../../core/components/Tabs';
import { Container, Row, Col } from 'reactstrap';
import * as actions from '../actions';
import { viewPermissionRefs } from '../../../core/components/UserGroupPermissions';
import DetergentCharts from '../components/DetergentCharts';
import { hasRole, RoleTypes } from '../../../core/components/Permission';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import PageActions from '../../../core/components/PageActions';
import { InventoryType } from '../../inventory/models';
import { showDialog } from '../../../core/actions';
import {
  selectCostPerCarChartFromDate,
  selectCostPerCarChartUntilDate,
  selectCostPerCarData,
  selectDetergentPods,
  selectReadingsFromDate,
  selectReadingsUntilDate,
} from '../reducer';
import { fetchProducts } from '../../products/actions';
import { fetchInventory } from '../../inventory/actions';
import Loader from '../../../core/components/Loader';

export const Component = ({ intl, chartData, setTabType, tabType, ...props }) => {
  const readingsAbortController = useAbortController(values(props.readingsDates));
  const dispatch = useDispatch();
  const showModal = (dialogType, dialogProps) => {
    dispatch(showDialog(dialogType, dialogProps));
  };
  const defaultDetergent = {
    id: null,
    title: 'Detergent Pod',
    description: 'Detergent Pod',
  };
  const detergentPods = useSelector(selectDetergentPods);
  const getReadings = (ids) => {
    const detergentIds = ids || Object.keys(props.detergentReadings);
    const currentDetergentPod = detergentPods.find((pod) => pod.title === tabType);
    if (detergentIds && detergentIds.length > 0) {
      const [fromDate, untilDate] = invokeMap(props.readingsDates, 'format', 'YYYY-MM-DD');
      props.getDetergentReadings(
        { fromDate, untilDate, detergentPodId: currentDetergentPod.id },
        readingsAbortController.signal
      );
    }
  };
  const fetchDetergentTabData = () => {
    dispatch(fetchProducts());
    dispatch(actions.getDetergentPods());
    dispatch(
      fetchInventory({
        inventoryType: InventoryType.Detergent,
        includeAll: true,
      })
    );
  };
  useEffect(() => {
    fetchDetergentTabData();
  }, [props.primarySite, props.multiSites, tabType, props.detergents]);
  useEffect(getReadings, [readingsAbortController]);

  const asOfDateProps = {
    setFromDate: props.setCostPerCarChartFromDate,
    setUntilDate: props.setCostPerCarChartUntilDate,
    ...props.costPerCarChartDates,
  };

  const detergentChartProps = (pod) => {
    return {
      detergentContainers: pod.detergentContainers,
      chartData,
      asOfDateProps,
      detergentPodId: pod.id,
    };
  };

  const tabComponents = map(detergentPods, (pod) => ({
    component: <DetergentCharts {...detergentChartProps(pod)} />,
    title: pod.title,
    type: pod.title,
    permissionKey: viewPermissionRefs.detergent,
  }));

  const getTabsProps = () => ({
    tabs: tabComponents,
    onClick: setTabType,
    selectedType: tabType,
  });

  const visibleComponent = detergentPods.length
    ? tabComponents.find((t) => t.type === tabType)
    : defaultDetergent;

  const getPageActions = () => {
    const pageActions = [];

    if (hasRole(props.role, RoleTypes.OperatorAdmin)) {
      pageActions.push({
        dialogType: 'ADD_DETERGENT',
        text: intl.formatMessage({ id: 'addDetergent' }),
        dialogProps: {
          products: props.products,
          inventory: props.inventory,
        },
      });
    }

    return pageActions;
  };

  return (
    <React.Fragment>
      <div>
        <Tabs {...getTabsProps()} />
      </div>
      <Container fluid className="detergent-tab-container">
        <Row className="detergent-title-container">
          <Col>
            <div className="detergent-date-container">
              <div className="detergent-readings-title-header">
                {intl.formatMessage({ id: 'detergentReadings' })}
              </div>
              <DateRangeSelector
                additionalStyles="btn btn-primary drs-detergents-page"
                dates={[props.readingsDates.fromDate, props.readingsDates.untilDate]}
                onSubmit={(dates) => {
                  props.setFromDate(dates.from);
                  props.setUntilDate(dates.until);
                }}
              />
            </div>
            <div>{intl.formatMessage({ id: 'detergentPod' })}</div>
          </Col>
          <Col>
            <div className="detergent-page-action">
              <PageActions
                actions={getPageActions()}
                actionFunction={showModal}
                additionalStyles="btn detergent-action-button"
              />
            </div>
          </Col>
        </Row>
        <div className="scroll-direction">{visibleComponent?.component || <Loader />}</div>
      </Container>
    </React.Fragment>
  );
};

Component.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  detergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  detergentReadings: PropTypes.shape({}).isRequired,
  readingsDates: PropTypes.shape({}).isRequired,
  costPerCarChartDates: PropTypes.shape({}).isRequired,
  showChart: PropTypes.func,
  setCostPerCarChartFromDate: PropTypes.func.isRequired,
  setCostPerCarChartUntilDate: PropTypes.func.isRequired,
  setReadingsFromDate: PropTypes.func.isRequired,
  setReadingsUntilDate: PropTypes.func.isRequired,
  removeReadings: PropTypes.func.isRequired,
  getDetergentReadings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  readingsDates: {
    fromDate: selectReadingsFromDate(state),
    untilDate: selectReadingsUntilDate(state),
  },
  costPerCarChartDates: {
    fromDate: selectCostPerCarChartFromDate(state),
    untilDate: selectCostPerCarChartUntilDate(state),
  },
  chartData: selectCostPerCarData(state),
});

const enhance = compose(connect(mapStateToProps, actions), injectIntl);

export const DetergentTabsContainer = React.memo(Component, isEqual);

export default enhance(DetergentTabsContainer);
