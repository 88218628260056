import React from 'react';
import { Card, CardTitle } from 'reactstrap';
import ButtonWithLoader from '../../../../core/components/ButtonWithLoader';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { showDialog } from '../../../../core/actions';
import { saveDetergentPod } from '../../../detergentsTemp/actions';
import Loader from '../../../../core/components/Loader';

interface Props {
  detergentPod: {
    id: number;
    description: string;
    title: string;
  };
  editDetergents: (detergentPod: any) => void;
}

const DetergentPodCard = (props: Props): JSX.Element => {
  const { detergentPod } = props;
  const intl: any = useIntl();
  const dispatch = useDispatch();
  const hideModal = () => dispatch({ type: 'HIDE_DIALOG' });
  const handleEditDetails = () => {
    const dialogProps = {
      onSubmit: saveDetergentPod,
      title: `${detergentPod.title} - ${intl.formatMessage({ id: 'editDetails' })}`,
      isNew: false,
      isOpen: true,
      hide: hideModal,
      detergentPod,
    };
    dispatch(showDialog('UPSERT_DETERGENT_POD', dialogProps));
  };

  return detergentPod ? (
    <Card
      body
      className="my-2"
      style={{
        borderRadius: '15px',
      }}
    >
      <CardTitle className="chart-title">{detergentPod.title}</CardTitle>
      <div style={{ marginTop: '10px' }}>
        <ButtonWithLoader
          handleClick={handleEditDetails}
          style="detergent-action-button-white button mr-2"
        >
          <span>{intl.formatMessage({ id: 'editPod' })}</span>
        </ButtonWithLoader>
        <ButtonWithLoader
          handleClick={() => props.editDetergents(detergentPod)}
          style="detergent-action-button"
        >
          <span>{intl.formatMessage({ id: 'editContainers' })}</span>
        </ButtonWithLoader>
      </div>
    </Card>
  ) : (
    <Loader />
  );
};

export default DetergentPodCard;
