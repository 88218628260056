import { PropTypes } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectAnalyticsEnabled,
  selectPermissions,
  selectRole,
  selectSite,
  selectUserGroup,
  selectUserName,
} from '../../store/authReducer';
import { selectModal } from '../../store/modal';
import * as actions from '../../views/login/actions';
import { selectWorkLogsDisabled } from '../../views/settings/reducer';
import { ModalRoot } from './ModalRoot';
import Navbar from './Nav';
import SideNav from './SideNav';
import { viewPermissionRefs } from './UserGroupPermissions';
import ZendeskSupportWidget from './ZendeskSupportWidget';

const getSideMenuUrl = (baseUrl, relativeUrl) => {
  const fullUrl = `${baseUrl}/${relativeUrl}`;
  return baseUrl ? fullUrl : null;
};

export const getSideMenuItems = (baseUrl) => {
  return [
    {
      to: getSideMenuUrl(baseUrl, 'overview'),
      icon: 'icon-home',
      label: 'overview',
      permissionRef: viewPermissionRefs.site,
    },
    {
      to: getSideMenuUrl(baseUrl, 'tasks'),
      icon: 'icon-text-document',
      label: 'tasks',
      permissionRef: viewPermissionRefs.task,
    },
    {
      to: getSideMenuUrl(baseUrl, 'maintenance'),
      icon: 'icon-tools',
      label: 'maintenance',
      permissionRef: viewPermissionRefs.view_maintenance_page,
    },
    {
      to: getSideMenuUrl(baseUrl, 'detergents'),
      icon: 'icon-progress-two',
      label: 'detergents',
      permissionRef: viewPermissionRefs.detergent,
    },
    {
      to: getSideMenuUrl(baseUrl, 'detergentsTemp'),
      icon: 'icon-progress-two',
      label: 'detergentsTemp',
      permissionRef: viewPermissionRefs.admin,
    },
    {
      to: getSideMenuUrl(baseUrl, 'washcounts'),
      icon: 'icon-area-graph',
      label: 'washCounts',
      permissionRef: viewPermissionRefs.wash_count,
    },
    {
      to: getSideMenuUrl(baseUrl, 'claims'),
      icon: 'icon-warning',
      label: 'claims',
      permissionRef: viewPermissionRefs.warranty,
    },
    {
      to: getSideMenuUrl(baseUrl, 'rewashes'),
      icon: 'icon-drop',
      label: 'rewashes',
      permissionRef: viewPermissionRefs.rewash,
    },
    {
      to: getSideMenuUrl(baseUrl, 'team'),
      icon: 'icon-user',
      label: 'team',
      permissionRef: viewPermissionRefs.team,
    },
    {
      to: getSideMenuUrl(baseUrl, 'expenses'),
      icon: 'icon-credit',
      label: 'expenses',
      permissionRef: viewPermissionRefs.expense,
    },
    {
      to: getSideMenuUrl(baseUrl, 'inventory'),
      icon: 'icon-dropbox',
      label: 'inventory',
      permissionRef: viewPermissionRefs.inventory,
    },
    {
      to: getSideMenuUrl(baseUrl, 'analytics'),
      icon: 'icon-line-graph',
      label: 'analytics',
      permissionRef: viewPermissionRefs.analytics,
    },
    {
      to: getSideMenuUrl(baseUrl, 'settings'),
      icon: 'icon-cog',
      label: 'settings',
      permissionRef: viewPermissionRefs.settings,
    },
  ];
};

// NOTE: could load theme from context,
const Main = (props) => {
  const { children, logout, siteId, modal, username, analyticsToggle, permissions } = props;
  const mobileWidth = 700;
  const [isNavOpen, setNavState] = React.useState(window.innerWidth >= mobileWidth);
  const sideMenuItems = getSideMenuItems(`/${siteId}`);

  return (
    <>
      <div className="app-main">
        <SideNav
          homeUrl="/"
          menuItems={sideMenuItems}
          username={username}
          analyticsEnabled={analyticsToggle}
          permissions={permissions}
          setNavState={setNavState}
          isOpen={isNavOpen}
          userGroup={props.userGroup}
          mobileBreakpoint={mobileWidth}
          logout={logout}
        />
        <main>
          <Navbar
            username={username}
            siteId={siteId}
            setNavState={setNavState}
            isNavOpen={isNavOpen}
          />
          <div className="page-container">{children}</div>
        </main>
      </div>
      <ModalRoot {...modal} />
      <ZendeskSupportWidget />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  logout: PropTypes.func.isRequired,
  siteId: PropTypes.string,
  modal: PropTypes.shape({
    modalType: PropTypes.string,
    modalProps: PropTypes.object,
  }),
  username: PropTypes.string,
  role: PropTypes.string,
  workLogsDisabled: PropTypes.bool,
  analyticsToggle: PropTypes.bool,
  permissions: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
};

const mapStateToProps = (state) => {
  const stringifiedSiteId = selectSite(state) ? selectSite(state).toString() : null;

  return {
    siteId: stringifiedSiteId,
    modal: selectModal(state),
    username: selectUserName(state),
    role: selectRole(state),
    workLogsDisabled: selectWorkLogsDisabled(state),
    analyticsToggle: selectAnalyticsEnabled(state),
    permissions: selectPermissions(state),
    userGroup: selectUserGroup(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
