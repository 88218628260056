import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import {
  getDetergents,
  getDetergentReadings,
  setReadingsFromDate,
  setReadingsUntilDate,
} from '../actions';
import {
  selectDetergentReadings,
  selectDetergentPods,
  selectReadingsFromDate,
  selectReadingsUntilDate,
  selectDetergentData,
} from '../reducer';
import DetergentTableContainer from './DetergentTableContainer';
import DetergentTabsContainer from './DetergentTabsContainer';

export const tempDetergents = ({
  fetchDetergents,
  detergents,
  detergentReadings,
  detergentPods,
  ...props
}) => {
  const siteId = useSelector(selectSiteExtId),
    multiSites = useSelector(selectMultiSiteSelection);
  const [tabType, setTabType] = useState(detergentPods[0]?.title || 'Detergent Pod');

  useEffect(() => {
    fetchDetergents();
  }, [siteId, multiSites]);

  return (
    <div className="container-fluid">
      <div>
        <DetergentTabsContainer
          detergents={detergents}
          setTabType={setTabType}
          tabType={tabType}
          detergentReadings={detergentReadings}
          readingsDates={props.readingsDates}
          setFromDate={props.setFromDate}
          setUntilDate={props.setUntilDate}
        />
      </div>
      <div className="detergent-page-content">
        <div className="detergent-table-edge">
          <DetergentTableContainer tabType={tabType} />
        </div>
      </div>
    </div>
  );
};

tempDetergents.propTypes = {
  detergents: PropTypes.arrayOf(PropTypes.object).isRequired,
  detergentReadings: PropTypes.shape({}),
  fetchDetergents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  detergentPods: selectDetergentPods(state),
  detergents: selectDetergentData(state),
  detergentReadings: selectDetergentReadings(state),
  readingsDates: {
    fromDate: selectReadingsFromDate(state),
    untilDate: selectReadingsUntilDate(state),
  },
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDetergents: getDetergents,
      getReadings: getDetergentReadings,
      setFromDate: setReadingsFromDate,
      setUntilDate: setReadingsUntilDate,
    },
    dispatch
  );

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(tempDetergents);
