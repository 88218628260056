import { PropTypes } from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSiteExternalId,
  selectSiteName,
  selectSiteTimeZone,
} from '../views/settings/reducer';
import { selectOperatorGroup } from '../store/authReducer';
export const AppContext = React.createContext({
  theme: {
    logo: '',
    favicon: '',
    hostName: '',
  },
  siteName: '',
  externalId: '',
  timeZone: '',
  isWashClub: false,
});

const AppProvider = ({ children }) => {
  const siteName = useSelector(selectSiteName);
  let theme = useSelector((state) => state.theme);

  const externalId = useSelector(selectSiteExternalId);
  const timeZone = useSelector(selectSiteTimeZone);
  const hostname = window.location.hostname;
  const port = window.location.port;
  const isWashClub =
    (hostname === 'localhost' && port === '3000') ||
    hostname === 'portal.wash.club' ||
    hostname === 'portal.staging-wash.club' ||
    hostname === 'portal.dev-wash.club';
  const opGroup = useSelector(selectOperatorGroup);

  const context = {
    theme,
    siteName,
    externalId,
    timeZone,
    isWashClub,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export default AppProvider;
