import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { isAuthenticated } from '../../store/authReducer';
import '../../styles/login.scss';
import LoginForm from './components/LoginForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import RegisterForm from './components/SetPasswordForm';
import { useAppContext } from '../../providers/AppProvider';

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const isUserAuthenticated = useSelector(isAuthenticated);

  useEffect(() => {
    if (isUserAuthenticated) {
      const redirect = parse(location.search).redirect || '/';

      history.push(redirect);
    }
  }, [isUserAuthenticated]);
  const { isWashClub } = useAppContext();
  const isForgotPassword = location.pathname.includes('forgot-password');
  const isRegisterForm = location.pathname.includes('set-password');
  const backgroundClass = isWashClub ? 'wash-club-login-container' : 'tx-login-container';
  const getPageForm = () => {
    if (isForgotPassword) {
      return <ForgotPasswordForm />;
    }

    if (isRegisterForm) {
      return <RegisterForm />;
    }

    return <LoginForm />;
  };

  return <div className={`${backgroundClass}`}>{getPageForm()}</div>;
};

export default Login;
